import { getToken } from '../../api/mapi'
import Cookie from 'js-cookie'

const mapiUser = {
  state: {
    access_token: '', // 控制菜单的展开还是收起
    access_token_expires_in: 0,
    advertiser_id: 0,
    refresh_token: '',
    refresh_token_expires_in: 0,
    user_id: 0
  },
  mutations: {
    SET_ACCESS_TOKEN: (state, access_token) => {
      state.access_token = access_token
    },
    SET_ACCESS_TOKEN_EXPIRES_IN: (state, access_token_expires_in) => {
      state.access_token_expires_in = access_token_expires_in
    },
    SET_ADVERTISER_ID: (state, advertiser_id) => {
      state.advertiser_id = advertiser_id
    },
    SET_REFRESH_TOKEN: (state, refresh_token) => {
      state.refresh_token = refresh_token
    },
    SET_REFRESH_TOKEN_EXPIRES_IN: (state, refresh_token_expires_in) => {
      state.refresh_token_expires_in = refresh_token_expires_in
    },
    SET_USER_ID: (state, user_id) => {
      state.user_id = user_id
    }
  },

  actions: {
    // 获取用户信息
    GetUserInfo({ commit }, authCode) {
      return new Promise((resolve, reject) => {
        getToken(authCode).then(res => {
          commit('SET_ACCESS_TOKEN', res.data.data.access_token)
          commit('SET_ACCESS_TOKEN_EXPIRES_IN', res.data.data.access_token_expires_in)
          commit('SET_ADVERTISER_ID', res.data.data.advertiser_id)
          commit('SET_REFRESH_TOKEN', res.data.data.refresh_token)
          commit('SET_REFRESH_TOKEN_EXPIRES_IN', res.data.data.refresh_token_expires_in)
          commit('SET_USER_ID', res.data.data.user_id)

          const expiresDate = new Date(Date.now() + 24 * 60 * 60 * 1000) // 过期时间为1天
          Cookie.set('myCookie', res.data.data.access_token, { expires: expiresDate, path: '/' })
          Cookie.set('myRefreshToken', res.data.data.refresh_token, { expires: expiresDate, path: '/' })
          Cookie.set('myID',res.data.data.advertiser_id,{ expires: expiresDate, path: '/' })
          Cookie.set('myUserID', res.data.data.user_id, { expires: expiresDate, path: '/' })
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 退出系统
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_ACCESS_TOKEN', '')
        commit('SET_ACCESS_TOKEN_EXPIRES_IN', 0)
        commit('SET_ADVERTISER_ID', 0)
        commit('SET_REFRESH_TOKEN', '')
        commit('SET_REFRESH_TOKEN_EXPIRES_IN', 0)
        commit('SET_USER_ID', 0)
        Cookie.remove('myCookie')
        reject()
      })
    }
  }
}
export default mapiUser
